/* No seu arquivo CSS ou em um arquivo de estilo */
.dropdown-toggle-icon-none::after {
    content: none !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Posição abaixo do botão de dropdown */
    left: 0; /* Alinhar à esquerda do botão de dropdown */
    z-index: 1000; /* Valor alto para sobrepor outros elementos */
    background-color: white;
    border: 1px solid #ccc;
    /* Outros estilos conforme necessário */
  }